import { Box, Button, Card, CardActions, CardContent, Container, Fade, Grid, Typography } from "@mui/material";
import searchImage from "../../../assets/search.png";
import matchImage from "../../../assets/6.jpg";
import talent from "../../../assets/4.jpg";
import theme from "../../../theme";

export default function WhatWeDo() {
    return (
        <Container sx={{ textAlign: 'center' }}>
           {/* laptops */}
            <Box sx={{
                 display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'block',
                    lg: 'block'
                },
                justifyContent: 'center',
                marginTop: 10,
                marginBottom: 10
            }}>
                <Fade in timeout={1600}>
                    <Grid container>
                        <Grid xs={12} md={4}>
                            <Card sx={{ width: '90%', height: 300 }}>
                                <CardContent sx={{ height: '80%' }}>
                                    <img src={searchImage} width={160} height={120} />
                                    <Typography variant="h6" color={'primary'}>
                                        We find people
                                        that will help make your business a success.
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    {/* <Button href="/openings" variant="contained">{}</Button> */}
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Card sx={{ width: '90%', height: 300 }}>
                                <CardContent sx={{ height: '80%' }}>
                                    <img src={matchImage} width={140} height={120} />
                                    <Typography variant="h6" color={'primary'}>
                                        We deliver perfect <span style={{
                                            color: theme.palette.secondary.main
                                        }}>matches</span>
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    {/* <Button href="/openings" variant="contained">{}</Button> */}
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Card sx={{ width: '90%', height: 300 }}>
                                <CardContent sx={{ height: '80%' }}>
                                    <img src={talent} width={140} height={120} />
                                    <Typography variant="h6" color={'primary'}>
                                        We deliver the best talent in Latin America
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    {/* <Button href="/openings" variant="contained">{}</Button> */}
                                </CardActions>
                            </Card>
                        </Grid>

                    </Grid>
                </Fade>
            </Box>

            {/* cellphones */}
            <Box sx={{
                 display: {
                    xs: 'block',
                    sm: 'block',
                    md: 'none',
                    lg: 'none'
                },
                justifyContent: 'center',
            }}>
                <Fade in timeout={1600}>
                    <Grid container>
                        <Grid xs={12} md={4}>
                            <Card sx={{ width: '90%', height: 300, margin: 2 }}>
                                <CardContent sx={{ height: '80%' }}>
                                    <img src={searchImage} width={160} height={120} />
                                    <Typography variant="h6" color={'primary'}>
                                        We find people
                                        that will help make your business a success.
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    {/* <Button href="/openings" variant="contained">{}</Button> */}
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Card sx={{ width: '90%', height: 300, margin: 2  }}>
                                <CardContent sx={{ height: '80%' }}>
                                    <img src={matchImage} width={140} height={120} />
                                    <Typography variant="h6" color={'primary'}>
                                        We deliver perfect <span style={{
                                            color: theme.palette.secondary.main
                                        }}>matches</span>
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    {/* <Button href="/openings" variant="contained">{}</Button> */}
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Card sx={{ width: '90%', height: 300, margin: 2  }}>
                                <CardContent sx={{ height: '80%' }}>
                                    <img src={talent} width={140} height={120} />
                                    <Typography variant="h6" color={'primary'}>
                                        We deliver the best talent in Latin America
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    {/* <Button href="/openings" variant="contained">{}</Button> */}
                                </CardActions>
                            </Card>
                        </Grid>

                    </Grid>
                </Fade>
            </Box>
        </Container>);
}