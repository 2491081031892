const translation = {
    title: 'Welcome to Laboria!',
    language: 'Language',
    nav: {
        home: 'Home',
        about: 'About',
        contact: 'Contact',
        openings: 'Openings',
        steps: 'Steps'
    },
    landing: {
        applyNow: 'Apply Now',
        contactUs: 'Contact Us',
        showMore: 'Show more...',
        legallyUs: `Don't you know how work legally in the UNITED STATES?`,
        helpYou: 'We help you!',
        applicantTitle: 'Would you like to apply for a job opportunity?',
        applicantDescription: 'We will help you during the proccess',
        clientTitle: ' Are you looking for top tier resources?',
        clientDescription: 'We provide the best solution for you.'
    },
    openings: {
        title: 'Job Openings',
        details: {
            description: "Description",
            location: "Location",
            salary: "Salary",
            apply: "Apply"
        },
        list: {
            position: 'Position',
            field: 'Field',
            description: 'Description',
            location: 'Location',
            options: 'Options'
        }
    },
    steps: {
        personalInfo: 'Personal information',
        dsForm: 'DS-160'
    },
    forms: {
        personalInfo: {
            idIsNotValid: 'There is an existing application with this Identification number',
            id: 'ID',
            name: 'First Name',
            middleName: 'Middle Name',
            lastName: 'Last Name',
            gender: 'Gender',
            maritalStatus: 'Marital Status',
            partnerName: 'Partner Name',
            haveKids: 'Do you have kids?',
            educationLevel: 'Education Level',
            profession: 'Profession or occupation',
            englishLevel: 'English Level',
            workExperience: 'Work Experience',
            havePassport: 'Do you have passport?',
            visa: 'Have you received any type of United States visa?',
            country: 'Country',
            state: 'State',
            city: 'City',
            address: 'Address',
            phone: 'Phone number',
            birthDate: 'Birthdate',
            email: 'E-mail',
            next: 'Next'
        },
        genderOptions: {
            male: 'Male',
            female: 'Female',
            other: 'Prefer not to say'
        },
        maritalStatusOptions: {
            single: 'Single',
            married: 'Married',
            divorced: 'Divorced',
            widowed: 'Widowed',
            separated: 'Separated'
        },
        educationalLevels: {
            noFormalEducation: 'No formal education',
            primaryEducation: 'Primary education',
            secondaryEducation: 'Secondary education',
            vocationalQualification: 'Vocational qualification',
            bachelorDegree: 'Bachelor degree',
            masterDegree: 'Master degree',
            doctorate: 'Doctorate or higher'
        },
        englishLevels: {
            basic: 'Basic',
            intermediate: 'Intermediate',
            advanced: 'Advanced',
            fluent: 'Fluent'
        },
        visaTypes: {
            No: "No",
            B2: "Visitor Visa (B-2)",
            B1: "Business Visa (B-1)",
            H1B: "Temporary Work Visa (H-1B)",
            F1: "Student Visa (F-1)",
            J1: "Exchange Visitor Visa (J-1)",
            K1: "Fiancé(e) Visa (K-1)",
            CR1: "Spouse Visa (CR-1/IR-1)",
            E2: "Investor Visa (E-2)",
            O1: "Artist/Academic Visa (O-1)",
            R1: "Religious Worker Visa (R-1)"
        },
        dsForm: {
            haveDirectRelativeInUS: "Do you have any direct relatives in the United States, excluding your parents?",
            relatives: "Detail who.",
            deniedVisa: "Have you ever been denied a visa?",
            canceledVisa: "Has your visa ever been canceled?",
            citizenRequest: "Have you ever applied for citizenship in the United States?",
            outOfCountryLastFiveYears: "Have you been out of the country in the last 5 years?",
            visitedCountries: "Which countries have you visited?",
            haveBeenOut: "Have you resided in another country for more than 6 months after turning 16?",
            triedEnterIllegallyToUs: "Have you ever tried to enter the United States illegally?"
        },
        contact: {
            companyName: "Company Name",
            companyDescription: "Company Description",
            contactName: "Contact Name",
            phoneNumber: "Phone Number",
            country: "Country",
            state: "State",
            city: "City",
            address: "Address",
            email: "Email",
            message: "Message",
            send: "Send"
        }
    },
    auth: {
        signUp: {
            email_label: 'Your email address',
            password_label: 'Create your password',
            email_input_placeholder: 'Your email address',
            password_input_placeholder: 'Your password',
            button_label: 'Sign up',
            loading_button_label: 'Signing up ...',
            link_text: "Don't have an account? Sign up",
            confirmation_text: "Check your email for the confirmation link"
        },
        signIn: {
            email_label: "Email address",
            password_label: "Your Password",
            email_input_placeholder: "Your email address",
            password_input_placeholder: "Your password",
            button_label: "Sign in",
            loading_button_label: "Signing in ...",
            social_provider_text: "Sign in with {{provider}}",
            link_text: "Already have an account? Sign in"
        },
        forgottenPassword: {
            email_label: "Email address",
            password_label: "Your Password",
            email_input_placeholder: "Your email address",
            button_label: "Send reset password instructions",
            loading_button_label: "Sending reset instructions ...",
            link_text: "Forgot your password?",
            confirmation_text: "Check your email for the password reset link"
        }
    },
    client: {
        setUpTitle: 'Before starting, we just need to fill out some information.',
        email: 'Email',
        organizationName: 'Organization Name',
        phoneNumber: 'Phone number'
    }
}

export default {
    translation
}