import { Box, Button, Card, CardActions, CardContent, CardHeader, Fade, Grid, Grow, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function LandingHome() {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                height: '90vh',
                width: '100%',
            }}>

            {/* big screen */}
            <Box
                alignItems={'center'}
                alignContent={'center'}
                sx={{
                    height: '100%',
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block',
                        lg: 'block'
                    },
                }}>
                <Grid container spacing={2} sx={{ height: 300 }}>
                    <Grow in={true} timeout={1000}>
                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ width: '80%', height: '100%' }}>
                                <CardContent sx={{ height: '80%' }}>
                                    <Typography variant={"h3"} color={'primary'}>
                                    {t('landing.applicantTitle')}
                                    </Typography>
                                    <Typography color={'primary'} fontWeight={500} variant={"subtitle1"}>{t('landing.applicantDescription')}</Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    <Button href="/openings" variant="contained">{t('landing.applyNow')}</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grow>

                    <Grow in={true} timeout={2000}>
                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ width: '80%', }}>
                                <CardContent sx={{ height: '80%' }}>
                                    <Typography variant={"h3"} color={'primary'}>
                                    {t('landing.clientTitle')}
                                    </Typography>
                                    <Typography color={'primary'} fontWeight={500} variant={"subtitle1"}>{t('landing.clientTitle')}</Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    <Button href="/client" variant="contained">{t('landing.showMore')}</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grow>

                </Grid>
            </Box>

            {/* mobile screen */}
            <Box
                alignItems={'center'}
                alignContent={'start'}
                sx={{
                    height: '100%',
                    marginTop: 5,
                    display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'none',
                        lg: 'none'
                    },
                }}>
                 <Grid container spacing={2} sx={{ height: 300 }}>
                    <Grow in={true} timeout={1000}>
                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ width: '80%', height: '100%' }}>
                                <CardContent sx={{ height: '80%' }}>
                                    <Typography variant={"h4"} color={'primary'}>
                                        {t('landing.applicantTitle')}
                                    </Typography>
                                    <Typography color={'primary'} fontWeight={500} variant={"subtitle1"}>{t('landing.applicantDescription')}</Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    <Button href="/openings" variant="contained">{t('landing.applyNow')}</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grow>

                    <Grow in={true} timeout={2000}>
                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ width: '80%', }}>
                                <CardContent sx={{ height: '80%' }}>
                                    <Typography variant={"h4"} color={'primary'}>
                                    {t('landing.clientTitle')}
                                    </Typography>
                                    <Typography color={'primary'} fontWeight={500} variant={"subtitle1"}>{t('landing.clientDescription')}</Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    <Button href="/client" variant="contained">{t('landing.showMore')}</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grow>

                </Grid>
            </Box>
        </Box >
    );
}