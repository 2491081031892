import { Box, Container, Fade, Grid, Typography } from "@mui/material";
import image from "../../../assets/logo-blanco.png";

export default function ClientAboutUs() {
    return (
        <Container sx={{ marginTop: 10, textAlign: 'center' }}>

            <Box sx={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <img
                    width={400}
                    height={200}
                    src={image}></img>

            </Box>
            <Fade in timeout={1400}>
                <Typography variant="h2" fontWeight={400} color={'white'}>
                    We <b>Hire</b> For <b>You</b>
                </Typography>
            </Fade>

        </Container >
    );
}